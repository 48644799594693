import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useCallApi} from '../../modules/utils'
import ComboBox from '../ComboBox'
import {Loading} from '../Loading'
import {Alert} from 'react-bootstrap'
type Props = {
  data: any
  deleteRow: () => void
  someInfo: any
  update: boolean
  index: number
  calculateGrandTotal: () => void
}
export const ItemProductSale: React.FC<Props> = ({
  update,
  data,
  deleteRow,
  someInfo,
  index,
  calculateGrandTotal,
}) => {
  const callApi = useCallApi()

  const [subtotal, setsubtotal] = useState<number>(data?.attributes?.sub_total || 0)
  const [products, setproducts] = useState<any>([])
  const [product, setProduct] = useState<any>()
  const [quantity, setQuantity] = useState<number>(data?.attributes?.quantity || 0)
  const productsFromStorage = localStorage.getItem('products')

  const [product_price, setProductPrice] = useState<any>(data?.attributes?.product_price)
  const [loading, setLoading] = useState(true)

  const updatePurchaseItem = (index: number, field: string, value: any) => {
    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items]
    
    updatedPurchaseItems[index][field] = value

    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    })
  }
  const getProducts = async () => {
    try {
      if (productsFromStorage) {
        const parsedProducts = JSON.parse(productsFromStorage)

        if (parsedProducts && parsedProducts.length > 0) {
          setLoading(false)

          setproducts(parsedProducts)
        }
      } else {
        setproducts([]) // Set products to an empty array or handle it as needed
      }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getProducts()
  }, [productsFromStorage, someInfo.dataSaleUpdated])

  useEffect(() => {
    if (products) {
      setProductPrice(product?.attributes?.product_price)
      updatePurchaseItem(index, 'product_price', product?.attributes?.product_price)
      updatePurchaseItem(index, 'product_id', product?.id)
    }
  }, [product])

  // console.log(product_price,update,data,data.data?.attributes?.purchase_price, product?.attributes?.price_purchase);
  useEffect(() => {
    // console.log(
    //   update ? "hh"+data?.attributes?.price_purchase :"jhj"+product?.attributes?.price_purchase
    //   );
    setsubtotal(update ? data?.attributes?.sub_total : 0)

    setProductPrice(
      update ? data?.attributes?.product_price : product?.attributes?.product_price
    )
    setQuantity(update ? data?.attributes?.quantity : product?.attributes?.quantity)
  }, [data])

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    // Allow only numeric characters and control keys (backspace, delete, arrows)
    if (!/^\d$/.test(keyValue) && !event.ctrlKey && !event.metaKey) {
      event.preventDefault()
    }
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseFloat(event.target.value)
    setQuantity(newQuantity)

    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items]

    updatedPurchaseItems[index]['sub_total'] =
      Math.round(product_price * newQuantity )

    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    })
    setsubtotal(updatedPurchaseItems[index]['sub_total'])

    calculateGrandTotal()
  }

  const handlePurchasePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const purchase = parseFloat(event.target.value)

    const updatedPurchaseItems = [...someInfo.dataSaleUpdated.sale_items]
    
    updatedPurchaseItems[index]['sub_total'] =  Math.round(purchase *updatedPurchaseItems[index]['quantity'])

    someInfo.setDataSaleUpdated({
      ...someInfo.dataSaleUpdated,
      sale_items: updatedPurchaseItems,
    })
    setsubtotal(updatedPurchaseItems[index]['sub_total'])
    calculateGrandTotal()
  }

  return (
    <>
      <tr>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data?.attributes?.id} />
              {update ? (
                data?.attributes?.product_name ? (
                  data?.attributes?.product_name
                ) : (
                  <ComboBox
                    setProduct={setProduct}
                    products={products}
                    idProduct={data?.attributes?.product_name}
                    index={index}
                    updatePurchaseItem={updatePurchaseItem}
                  />
                )
              ) : loading ? (
                <Loading />
              ) : (
                <ComboBox
                  setProduct={setProduct}
                  products={products}
                  idProduct={data?.attributes?.product_name}
                  index={index}
                  updatePurchaseItem={updatePurchaseItem}
                />
              )}
              {/* <input className='text-dark fw-bold  d-block fs-6' type="text" value={"P1"} 
                    
                      /> */}
            </div>
          </div>
        </td>
        {/* <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={data?.attributes?.id}  />

                   
                     <input readOnly={true} className='text-dark fw-bold  d-block fs-6'type="text" value={ 
                        update ?
                        ( data?.attributes?.in_stock ):
                        (product?.attributes?.in_stock)
                      } 
                      style={{width:"15vh"}}
                    //    onChange={(e)=>{                        
                    //       setMarque(e.target.value)
                    //   }}
                      />
                    
                    </div>
                  </div>
                </td> */}
        {/* <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={data?.attributes?.id}  />

                      <input readOnly={true} className='text-dark fw-bold  d-block fs-6'type="text" value={ 
                        update ?
                        ( data?.attributes?.in_stock_invoiced ):(product?.attributes?.in_stock_invoiced)
                      } 
                      style={{width:"15vh"}}
                    //    onChange={(e)=>{                        
                    //       setMarque(e.target.value)
                    //   }}
                      />
                    
                    
                    </div>
                  </div>
                </td> */}
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data?.attributes?.id} />

              <input
                className='text-dark fw-bold  d-block fs-6'
                type='text'
                value={quantity > 0 ? quantity : 0}
                style={{width: '15vh'}}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  handleQuantityChange(e)
                  updatePurchaseItem(index, 'quantity', e.target.value)
                }}
              />
            </div>
          </div>
        </td>

        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input
                className='text-dark fw-bold d-block fs-6'
                type='text'
                value={product_price}
                style={{width: '16vh'}}
                onChange={(e) => {
                  setProductPrice(e.target.value)
                  handlePurchasePriceChange(e)
                  updatePurchaseItem(index, 'product_price', e.target.value)
                }}
              />
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input
                className='text-dark fw-bold  d-block fs-6'
                type='text'
                value={subtotal > 0 ? subtotal.toFixed(2) : 0}
                style={{width: '16vh'}}
                //    onChange={(e)=>{
                //       setMarque(e.target.value)
                //   }}
              />
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            {/* <button
                      type={'submit'}
                      title='save'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' 
                    //   onClick={()=>updateMarque(id)}
                    >
                      {
                        loading?<div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>:<KTSVG 
                        path='/media/icons/duotune/general/gen005.svg'
                          className='svg-icon-3' />
                      }
                          
                      
                    </button> */}
            <button
              title='drop'
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
              onClick={deleteRow}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 ' />
            </button>
          </div>
        </td>
      </tr>
    </>
  )
}

