import React, {ChangeEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {createFormData, useCallApi} from '../../modules/utils'
import {number, string} from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import {Loading} from '../Loading'
import {check} from 'prettier'
import {useAuth} from '../../modules/auth'
import { Autocomplete, TextField } from '@mui/material'

type Props = {
  data: any

  // deleteVille:(id:number)=>void
}
export const ModalUpdateCategorie: React.FC<Props> = ({data}) => {
  const {categories} = useAuth()
  const callApi = useCallApi()

 const [loading, setLoading] = useState(false)
  const [selectedCategorie, setselectedCategorie] = useState<any>(data.attributes?.parent?.id);

  const [productupdate, setProductUpdate] = useState({
    color: '',
    name: '',
    code: '',

    status: data.attributes.status,
  })
  useEffect(() => {
    setProductUpdate({
      color: data?.attributes.color,
      name: data?.attributes.name,
      code: data?.attributes.code,
      
      status: data?.attributes.status,
    })
    setselectedCategorie(data.attributes?.parent?.id)
  }, [data.id])
  // const getCategories = async () => {
  //     const {data} = await callApi({
  //       route: 'api/product-categories',
  //       method: 'GET',
  //     })
  //     setCategories(data.data)
  //     // setLoading(false)
  //   }

  
  const updateProduct = async () => {
    setLoading(true)
    const dataTosend = {
      color: productupdate.color,
      name: productupdate.name,
      status: productupdate.status == 1 ? true : false,
      product_category_id:selectedCategorie
    }

    // const formData = createFormData(dataTosend, image)
    try {
      const dat = await callApi({
        route: `api/product-categories/` + data.id,
        method: 'PUT',
        body: dataTosend,
      })
      toast.success('modifié avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }

    // setLoading(false)
    // Toast.success('modifié')
  }

  return (
    <>
      <div
        className='modal fade'
        id={'ModalUpdateCategorie' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' >
          <div className='modal-content'>
            <div className='modal-header ' >
              <h1 className='modal-title fs-5 ' id='exampleModalLabel'>
                Modification de catégorie: {data.attributes.name}
              </h1>
              <button
                type='button'
                className='btn-close '
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body' >
              <form>
                <div className='row mb-4'>
                  <div className='col-10'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Nom :
                      </label>
                      <input
                        type='text'
                        value={productupdate?.name}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setProductUpdate({...productupdate, name: e.target.value})
                        }
                      />
                    </div>
                  </div>
                  
                </div>
                
               
                <div className='row mb-4'>
                  <div className='form-group'>
                    
                  <Autocomplete
    className='solid'
    disablePortal
    id="combo-box-demo"
    options={categories}
    getOptionLabel={(option) => option?.attributes?.name}
    value={categories.find((category:any) => category?.id == selectedCategorie) || null}
    sx={{ backgroundColor: "white" }}
    onChange={(event, newValue) => {
        setselectedCategorie(newValue?.id);
    }}
    renderInput={(params) => <TextField {...params} label="Parent" sx={{ color: "black" }} />}
/>


                  </div>
                </div>
               
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='button' onClick={updateProduct} className='btn btn-primary'>
                  valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
