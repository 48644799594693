import React, {useEffect, useState} from 'react'
import {ListProductPurhase} from '../../components/purchase/ListProductPurhase'
import {useCallApi} from '../../modules/utils'
import {useNavigate, useParams} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useAuth } from '../../modules/auth'
import { ListProductSale } from '../../components/AddSaleInvoiced/ListProductSale'

type Props={
  returned:boolean
}
export const DetailsSaleInvoiced:React.FC<Props> = ({returned}) => {
  // const [detailsP, setDetailP] = useState<any>()
  const [clients, setClients] = useState<any[]>([])
  const navigate=useNavigate()
  
  const {dataSaleInvoicedUpdated, setDataSaleInvoicedUpdated,hasPermission,getProducts,products} = useAuth()

  const [loading, setLoading] = useState(true)
  const {id} = useParams()

  const callApi = useCallApi()
  const getSales = async () => {
    try {
        const data = await callApi({
          route: returned? 'api/purchases-return/' + id:'api/sale_invoiceds/' + id,
          method: 'GET',
        })

        setDataSaleInvoicedUpdated({
          ...dataSaleInvoicedUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          customer_id: data.data.data.attributes.customer_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          sale_items: data.data.data.attributes.sale_items.data,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
          token: data.data.data.attributes.token,

        
        })
      
      
      // setDetailP(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getClients = async () => {
    try {
      const data = await callApi({
        route: 'api/customers',
        method: 'GET',
      })

      setClients(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  
 

  useEffect(() => {
    console.log('Inside useEffect');
    getSales();
  }, []);
  
  useEffect(() => {
    getClients()

  }, [])
  
  console.log("jj"+id);
  
  // console.log(detailsP);
  const saveData =async() => {
    setLoading(true)
    
    const transfData=  {
      id: dataSaleInvoicedUpdated.id,
      date: dataSaleInvoicedUpdated.date,
      warehouse_id: dataSaleInvoicedUpdated.warehouse_id,
      customer_id: dataSaleInvoicedUpdated.customer_id,
      discount: dataSaleInvoicedUpdated.discount,
      tax_rate: dataSaleInvoicedUpdated.tax_rate,
      sale_items: dataSaleInvoicedUpdated.sale_items.map((item:any) => ({
        id:item?.data?.id==undefined ? null:item.data.id,
        product_cost:0,
        discount_amount: 0,
        discount_type: '1',
        discount_value: 0,
       
        net_unit_cost: 0,
        tax_amount: 0,
        tax_type: '1',
        product_price: item.product_price ? item.product_price:item.data.attributes.product_price,

        tax_value: 0,
        product_id:item.product_id? item.product_id:item.data.attributes.product_id,
        sub_total: item.sub_total>=0 ? item.sub_total:item.data.attributes.sub_total,
        quantity: item.quantity>=0? item.quantity:item.data.attributes.quantity,
      })),
      shipping: dataSaleInvoicedUpdated.shipping,
      grand_total: 0,
      paid_amount: dataSaleInvoicedUpdated.paid_amount,
      payment_type: dataSaleInvoicedUpdated.payment_type,
      payment_status: dataSaleInvoicedUpdated.payment_status,
      token:dataSaleInvoicedUpdated.token,
      notes: dataSaleInvoicedUpdated.notes,
      status: dataSaleInvoicedUpdated.status,
    };
    transfData.grand_total = transfData.sale_items.reduce(
      (total:any, item:any) => total + item.sub_total,
      0
    );
  console.log(transfData);
    try {

      const dat = await callApi({
        route: returned?`api/purchases-return/`+id:`api/sale_invoiceds/`+id,
        method: 'PUT',
        body:transfData ,
      })


       toast.success("modifier avec succes")
       setLoading(false)
       getSales()
       !returned? navigate("/list_sales_invoiced"):navigate("/purchases-returned")
       await getProducts()
       localStorage.setItem("products", JSON.stringify(products));


     } catch (error:any) {
      
      toast.error(error.response.data.message)
      setLoading(false)
     }
  }

  
  return (
    <>
    <ToastContainer/>
    <div className="d-flex justify-content-end">
    <button onClick={() => navigate(-1)}  className="btn btn-sm btn-primary shadow-sm mr-1">
        <i className="fas fa-arrow-circle-left"></i>
        <span className="d-none d-sm-inline-block font-weight-bold">Retoure</span>
    </button>
    </div>
   
      <div className='row mb-3'>
      
      <div className='col-4'>
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className=" fw-bold">
            <label >Client  :</label> 
            </div>
            <div className="col">
            <select
              name=''
              id=''
              className='form-select   form-select-solid'
              onChange={(e) => setDataSaleInvoicedUpdated({...dataSaleInvoicedUpdated, customer_id: e.target.value})}

            >
              <option value="">choisir..</option>
              {clients &&
                clients.map((client:any, index:number) => {
                  return (
                    <option
                      key={index}
                      value={client.id}
                      selected={client.id==dataSaleInvoicedUpdated.customer_id}
                    >
                      {client.attributes.name}
                    </option>
                  )
                })}
            </select>
            </div>
         
        </div>
        </div>
        <div className="col-4 ">
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className=" fw-bold">
            <label >Date de vente  :</label>
            </div>
            <div className="col">
            <input
                            type='datetime-local'
                            name='start_date'
                            onChange={(e) => setDataSaleInvoicedUpdated({...dataSaleInvoicedUpdated, date: e.target.value})}
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                            value={dataSaleInvoicedUpdated.date=="" ? dataSaleInvoicedUpdated.date:new Date(dataSaleInvoicedUpdated?.date).toISOString().slice(0, 16)} // Format the date here
                            />
                </div>
                </div>
      </div>
      
     
      <div className="col-2 ">
        <div className=" row bg-white w-100 p-3 border rounded-2  ">
          <div className=" fw-bold">
            <label >Remise % :</label>
            </div>
            <div className="col">
            <input
                          onChange={(e) => setDataSaleInvoicedUpdated({...dataSaleInvoicedUpdated, discount: e.target.value})}
                           value={dataSaleInvoicedUpdated.discount}
                            type='text'
                            className='form-control  form-control-solid'                            id='exampleInputEmail1'
                            aria-describedby='text'
                          />
                </div>
                </div>
     </div>
      </div>
      

      <ListProductSale
        saveData={saveData}
        total={dataSaleInvoicedUpdated?.grand_total}
        someInfo={{dataSaleInvoicedUpdated, setDataSaleInvoicedUpdated,loading}}
        update={true}
      />
    </>
  )
}
