import React, {useState, useEffect} from 'react'
import {Field, Form, Formik} from 'formik'
import {ToastContainer, toast} from 'react-toastify'
import {useAuth} from '../../modules/auth'
import {useCallApi} from '../../modules/utils'
import {Loading} from '../Loading'

interface Role {
  id: number
  attributes: {
    display_name: string
  }
}
export const ModalAddClient = () => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState<any>([])
  const [chejed, setchejed] = useState(false)
  const [role_id, setRole_id] = useState<any>()
  const initialValues = {
    email: '',
    name: '',
    adress: '',
    
    phone: '',
  }

  const handleSubmit = async (values: any) => {
    setLoading(true)
    // const dataToSend = {
    //   role_id: role_id,
    //   email: values.email,
    //   phone: values.tel,
    //   name: values.first_name,
    //   last_name: values.last_name,
    //   code: values.code,
    //   password: values.password,
    //   active: values.status ? 1 : 0,
    // }
    // console.log(dataToSend)

    try {
      //   console.log(dataToSend)
      const data = await callApi({
        route: 'api/customers',
        method: 'POST',
        body: values,
      })
      toast.success('Ajouté avec succès')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      <div
        className='modal fade'
        id='ModalAddClient'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog  modal-dialog-scrollable modal-lg' role='document'>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, {resetForm}) => {
              handleSubmit(values)
              resetForm()
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header '>
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouveau Client
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body '>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Nom & prenom :
                            </label>
                            <Field
                              name='name'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Email:
                            </label>
                            <Field
                              name='email'
                              type='email'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              Adresse :
                            </label>
                            <Field
                              name='adress'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>

                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Tel :
                            </label>
                            <Field
                              name='phone'
                              type='phone'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              Raison Social :
                            </label>
                            <Field
                              name='raison_social'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>

                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Cordonnée :
                            </label>
                            <Field
                              name='cordonnee'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Activité :
                            </label>
                            <Field
                              name='activite'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                       
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              RC :
                            </label>
                            <Field
                              name='rc'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              ICE :
                            </label>
                            <Field
                              name='ice'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Mode de paiement :
                            </label>
                            <Field
                              as='select'
                              name='payment_type'
                              id=''
                              className='form-select   form-select-solid'
                            >
                              <option value=''>choisir..</option>
                              <option value={1}>Espèces</option>
                              <option value={2}>Chèques</option>
                              <option value={2}>Effets</option>
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                               Délai de paiement:
                            </label>
                            <Field
                              name='deadline_payment'
                              type='date'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Plafond Client :
                            </label>
                            <Field
                              name='plafond_customer'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary'>
                        <Loading />
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-primary'>
                        valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
