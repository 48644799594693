import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'
import SearchComponent from '../SearchComponent'
import { useAuth } from '../../modules/auth'
import { Link } from 'react-router-dom'
import { FilterPucrhase } from '../purchase/FilterPucrhase'
import { ItemPurchase } from '../purchase/ItemPurchase'
import { ItemStock } from './ItemStock'
import { totalmem } from 'os'
import { Loading } from '../Loading'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListStock:React.FC<Props> = ({states})  => {
  const [stocks, setStocks] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]); // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const {currentUser,hasPermission} = useAuth()
  const [filterStock, setFilterStocks] = useState<any[]>([])
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [codeBar, setcodeBar] = useState("")
  const callApi = useCallApi()
  const getStocks = async () => {
    try {
      setLoading(true)
      const {data} = await callApi({
        route: `api/get-products-count?start_date=${start_date}&filter[code]=${codeBar}&filter[name]=${searchTerm}&end_date=${end_date}&page[size]=10&page[number]=${states.currentPage}&comptabilise=${hasPermission("manage_stock_invoiced")  && hasPermission("manage_stock")  ?0:1}`,
        method: 'GET',
      })

      const initialpurchases = data.data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))
     
      

      setStocks(initialpurchases)
      states.setTotalItems(data.data.total)
      states.setTotalPages(Math.ceil(states.totalItems / data.data.per_page))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching product data:', error)
    }
  }

//   const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const checked = event.target.checked
//     const updatedpurchases = stocks.map((stock) => ({
//       ...stocks,
//       checked,
//     }))
//     if (checked) {
//       const checkedProductIds = updatedpurchases.map((product) => product.id)
//       // Store the checkedProductIds in your table or wherever you need
//       setItemsSelected(checkedProductIds.length)
//       console.log(checkedProductIds)
//     }else{
//       setItemsSelected(0)

//     }
//     const checkedIds = updatedpurchases
//       .filter((product) => product.checked)
//       .map((product) => product.id);
//     setCheckedProductIds(checkedIds)
//     setStocks(updatedpurchases)
//   }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
    const updatedpurchases = stocks.map((product) =>
      product.id === productId ? { ...product, checked } : product
    );
    setStocks(updatedpurchases);
    const checkedIds = updatedpurchases
      .filter((product) => product.checked)
      .map((product) => product.id);
    setCheckedProductIds(checkedIds);
    setItemsSelected(checkedIds.length)
    
    }
//   const deleteProduct = async (ids:number[]) => {
//     ids.map(async(id:number)=>{
//       if (window.confirm('Voulez-vous vraiment supprimer ce produit !' + id)) {
//         const filteredposts = purchases.filter((product: any) => product.id !== id)
//         setStocks(filteredposts)
//         setItemsSelected(0)
        
//         toast.success('Produit   supprimé')
//         const {data} = await callApi({
//           route: 'api/purchases/' + id,
//           method: 'DELETE',
//         })
//       }
//     })
   
//   }




useEffect(() => {
  getStocks()

}, [])

  useEffect(() => {
    getStocks()
 
  }, [states.currentPage, states.totalItems,start_date,end_date,codeBar,searchTerm])

  
  
  return (
    <>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>

          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Stocks
              <div className="row">
                <div className="col">
                    <SearchComponent
                placeholder={'Recherche Produit'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
                </div>
                <div className="col">
                   <div className='d-flex align-items-center position-relative my-1 'style={{marginLeft:"7px"}}>
          <KTSVG
            path='/media/icons/duotune/ecommerce/ecm010.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className={'form-control form-control-solid w-250px ps-14 '}
            placeholder={"Scanner le code"}
            value={codeBar}
            onChange={(e) => setcodeBar(e.target.value)}
          />
          
        </div>
                </div>

            
             
              </div>
              {/* <SearchComponent placeholder={"Recherche Achat"} searchQuery={[searchTerm,setSearchTerm]} /> */}
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
             <>
      {/* begin::Filter Button */}
      <>
             <div className='me-3'>
             <label className=' fs-7 text-gray-500 fw-bold'>Date Début:</label>
        <input
          type='date'
          className='form-control  form-control-solid'
          onChange={(e)=>{
            setStartDate(e.target.value) 
            }}
          // onBlur={handleBlur}
          // value={values.cp_date_debut}
        />
       
      </div>
      <div className='me-3'>
      <label className=' fs-7 text-gray-500 fw-bold'>Date Fin:</label>

        <input
          type='date'
          name=''
          className='form-control  form-control-solid'
          
          min={start_date}
          onChange={(e)=>{setEndDate(e.target.value) 
            
          }}
          // onBlur={handleBlur}
          // value={values.cp_date_debut}
        />
       
      </div>
     
 
            </>
      {/* end::SubMenu */}
    </>
            

         
          </div>
          
        </div>
      
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                   
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className=' '>Produit</th>
                    <th className=' text-center'> En Stock Comptabilisé</th>
                    <th className=' text-center'> En Stock</th>

                    <th className=' text-center'>Entrés</th>
                    <th className=' text-center'>Sorties</th>

                  
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {loading?<Loading/>: stocks.map((product: any, index: any) => {
                    return (
                      <ItemStock
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                <tfoot>
         
        </tfoot>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
