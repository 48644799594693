import React from 'react'

import {Link} from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import ModalUpdateClient from './ModalUpdateClient';

// ... (import statements remain unchanged)

type Props = {
  data: any;
  handleProductCheckboxChange: any;
  deleteProduct?: (id: number) => void;
};

export const ItemClient: React.FC<Props> = ({
  data,
  handleProductCheckboxChange,
  deleteProduct,
}) => {
  const optionalAttributes = [
    'activite',
    'payment_type',
    'deadline_payment',
    'plafond_customer',
    'raison_social',
    'ice',
    'rc',
    'cordonnee',
    
  ];
  
  return (
    <>
      <tr>
        <td>
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} />
              <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.name} </span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.email}</span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
            <span
              className='fw-bold  d-block fs-6 badge badge-light-success mx-auto'
              style={{ width: 'fit-content' }}
            >
              {data.attributes?.phone}
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-center d-block fs-6'>
             
                  {data.attributes?.address}
               
            </span>
          </div>
        </td>
        {/* Additional columns based on optional attributes */}
        {optionalAttributes.map((attribute, index) => (
          <td className={`bg-white`} key={index}>
            <div className='d-flex justify-content-center flex-column'>
              <span className={`text-dark fw-bold text-center d-block fs-6`}>
                {attribute === 'payment_type' ? (
                  data.attributes?.[attribute] === 1 ? (
                    'Chèque'
                  ) : data.attributes?.[attribute] === 2 ? (
                    'Espèces'
                  ) : (
                    'Effets'
                  )
                ) : (
                  data.attributes?.[attribute]
                )}
              </span>
            </div>
          </td>
        ))}
        <td className="position-sticky" style={{right:0,background:"white"}}>
          <div className='d-flex  bg-white justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
            <button
            
                type='button'
                data-bs-toggle='modal'
                data-bs-target={'#ModalUpdateClient' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
            </div>
          </div>
        </td>
      </tr>       
             <ModalUpdateClient data={data} />

    </>
  );
};

