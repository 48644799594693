/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../../../app/modules/utils'
import { date } from 'yup'

type Props = {
  className: string
}

const PaymentPaye: React.FC<Props> = ({className}) => {
  const callApi = useCallApi()

  const [paymentData, setPaymentData] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(new Date().toISOString().split('T')[0]);
  const [selectedPaymentType, setSelectedPaymentType] = useState<number>(1);

  const fetchPayments = async () => {
    try {
      if (selectedDate) {
        const { data } = await callApi({
          route: `api/payments-report-purchases?date=${selectedDate}&payment_type=${selectedPaymentType}`,
          method: 'GET',
        });
        setPaymentData(data.data);
      }
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  };



  useEffect(() => {
    
    fetchPayments();
    
  }, [selectedDate,selectedPaymentType])
  

  return (
    <div className=' bg-white rounded' style={{padding: '1%', marginBottom: '1%'}}>
       <h4 className='card-title align-items-start flex-column p-5'>
          <span className='card-label fw-bold '>Paiements payés</span>
        </h4>
        <ul className='nav mb-2' style={{alignItems: 'center', justifyContent: 'space-around'}}>
      <li className='nav-item w-25'>
        <a
                    onClick={() => setSelectedDate(new Date().toISOString().split('T')[0])}

            className='nav-link btn btn-sm btn-color-muted btn-active active btn-active-light-primary active fw-bold px-4'
          data-bs-toggle='tab'
          href='#kt_table_widget_5_tab_1'
        >
          Aujourd'hui
        </a>
      </li>

      <li className='nav-item w-25'>
        <a
                    onClick={() => setSelectedDate(new Date(Date.now() + 86400000).toISOString().split('T')[0])}

            className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
          data-bs-toggle='tab'
          href='#kt_table_widget_5_tab_2'
        >
          demain
        </a>
      </li>
      <li className='nav-item w-25'>
        <div className='me-3'>
          <input
            className='form-control  form-control-solid nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
            data-bs-toggle='tab'
            type='date'
            onChange={(e:any) => setSelectedDate(e.target.value)}

            // onBlur={handleBlur}
            // value={values.cp_date_debut}
          />
        </div>
      </li>
    </ul>
    <div className='card-header border-0 pt-5'>
    <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9  pb-3'>
          <div className='me-2'>
            {/* <span className='fw-bold text-gray-800 d-block '>Paiements payés</span> */}
            {/* <span className='text-gray-400 fw-semibold'>Oct 8 - Oct 26 2021</span> */}
          </div>
          <div className='card-toolbar '>
      
      <ul className='nav'>
       <li className='nav-item'>
           <a
             onClick={()=>setSelectedPaymentType(1)}
             className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-info active fw-bold px-4'
             data-bs-toggle='tab'
             href='#kt_table_widget_5_tab_1'
           >
             Espèces
           </a>
         </li>
         
         <li className='nav-item'>
           <a
             onClick={()=>setSelectedPaymentType(2)}
             className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-success fw-bold px-4 me-1'
             data-bs-toggle='tab'
             href='#kt_table_widget_5_tab_2'
           >
             Chèques
           </a>
         </li>
         <li className='nav-item'>
           <a
             onClick={()=>setSelectedPaymentType(3)}
             className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger  fw-bold px-4 me-1'
             data-bs-toggle='tab'
             href='#kt_table_widget_5_tab_3'
           >
             Effets
           </a>
         </li>
      
       </ul>
     </div>
        </div>
      
      </div>

      <div className='card-body py-3' style={{height:"30rem",overflowX: "auto"}}>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                  <th className='p-0  min-w-50px'></th>
                 {/* <th className='p-0 min-w-50px'></th>
                 <th className='p-0 min-w-140px'></th> */}
                 <th className='p-0 w-150px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {paymentData &&
                    paymentData.map((categorie: any, index: number) => {
                      return (
                        <tr  key={index}
                        className="border-top"
                        data-bs-toggle="modal"
                        data-bs-target={'#modalproductCategorie' + index}>
                       <td className='text-start '><b>Proprietaire :</b>{categorie.proprietaire} - <b>Porteur :</b> {categorie.porteur} </td>
                          {/* <td></td>

                          <td className='text-end text-muted fw-semibold'></td>*/}
                          <td className='text-end'></td> 
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{categorie.amount}  MAD</span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    {/* <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-140px'></th> */}
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {paymentData &&
                    paymentData.map((categorie: any, index: number) => {
                      return (
                        <tr key={index} className='border-top'>
                          <td>{categorie.name}</td>
                          {/* <td></td>

                          <td className='text-end text-muted fw-semibold'></td> */}
                          <td className='text-end'></td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{categorie.total} MAD</span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px'></th>
                    {/* <th className='p-0 min-w-50px'></th>
                    <th className='p-0 min-w-140px'></th> */}
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {paymentData &&
                    paymentData.map((categorie: any, index: number) => {
                      return (
                        <tr key={index} className='border-top'>
                          <td>{categorie.name}</td>
                          {/* <td></td>

                          <td className='text-end text-muted fw-semibold'></td> */}
                          <td className='text-end'></td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{categorie.total} MAD</span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>

    
    </div>
  )
}

export {PaymentPaye}
