import React, { useEffect, useState } from 'react'
import { useCallApi } from '../../modules/utils'
import { ToastContainer, toast } from 'react-toastify'
import { ItemDetailsSubVente } from './ItemDetailsSubVente'
import { Loading } from '../Loading'


type Props = {
    data: any
  
    // deleteVille:(id:number)=>void
  }
export const ModalDetailsSubVente:React.FC<Props> = ({data}) => {
    const callApi = useCallApi()
     const [dataUpdated, setDataUpdated] = useState<any>(null)
   
     const [loading, setLoading] = useState(false)
     useEffect(() => {
      setDataUpdated(data.attributes)
     }, [])
     
 const saveData =async() => {
    try {
      setLoading(true)
      
      // setSenddata(data.attributes)
      
      const saleItemsArray = dataUpdated.sale_items.data.map((item:any) => item.attributes);
       saleItemsArray.map((item:any) =>delete item.sale_unit);
       let type = null;
       if (dataUpdated?.payment_type === "CASH") {
         type = 1;
       } else if (dataUpdated?.payment_type === "CHEQUE") {
         type = 2;
       } else if (dataUpdated?.payment_type === "OTHER") {
         type = 4;
       } else if (dataUpdated?.payment_type === "BANK_TRANSFER") {
         type = 3;
       }
       
      const modifiedObject = {
    ...dataUpdated,
    payment_type:type,
    sale_items: saleItemsArray,
   
  };

      // getdata["status"]=dataUpdated.status
      // console.log(getdata)

      const dat = await callApi({
        route: `api/sales/`+data.id,
        method: 'PUT',
        body: modifiedObject,
      })
       toast.success("modifiée vec succes")
       setLoading(false)

     } catch (error:any) {
      setLoading(false)

      toast.error(error.response.data.message)
      // setLoading(false)
     }
   

  }
  
  return (
    <>
 <div className="modal fade" id={"modaldetailssubventes"+data.id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered" >
        <div className="modal-content">
          <div className="modal-header ">
            <h1 className="modal-title fs-5 " id="exampleModalLabel">Commande {data.id}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
          <div className="row ">
    <div className="col-12 border-left-primary col-sm-12 col-md-12 col-lg-6 mb-2">
        <div className="card border-left-primary shadow h-100" style={{borderLeft:"6px solid #4e73df"}}>
            <div className="card-body">
                <div className="row">
                    <div className="fw-bold text-100 text-primary text-uppercase mb-1 col">N° de Commande:{data.id}</div>
                </div>
                <div className="mb-0 fw-bold text-gray-700">Crée le: {new Date(data.attributes.created_at).toISOString().slice(0, 10)+" "+new Date(data.attributes.created_at).toLocaleTimeString()}   </div>
                <div className="mb-0 fw-bold text-gray-700">Crée Par: 
                <span className='text-uppercase'>{data.attributes.caissier_name}</span> </div>
                <div className="mb-0 fw-bold text-gray-700">Total: {data.attributes.grand_total} DH</div>

            </div>
        </div>
    </div>

    <div className="col-12  border-left-primarycol-sm-12 col-md-12 col-lg-6 mb-2">
        <div className="card border-left-success shadow h-100" style={{borderLeft:"6px solid #1cc88a"}}>
            <div className="card-body">
                <div className="fw-bold  text-xs text-success text-uppercase mb-1">Détail de Commande</div>
                <div className="row">
                    <div className="text-gray-900 col-auto fw-bold">Statut:</div>
                    <div className="col">
                    <select className="form-control  form-control-sm" data-val="true" data-val-required="The OrderStatus field is required."
                                      onChange={(e) => setDataUpdated({...dataUpdated, status: e.target.value})}
                        id="OrderStatus" name="OrderStatus" >
                          <option  value={"en cours"} selected={data.attributes.status=="en cours"}>En cours</option>
                          <option value={"valide"}selected={data.attributes.status=="valide"}>Validée</option>
                          <option value={"annuler"}selected={data.attributes.status=="annuler"}>Annulée</option>
                             </select>
                    </div>
                </div>
                <div className="row mt-2">
                <div className="text-gray-900 col-auto fw-bold ">Paiement:</div>
                    <div className="col-2">
                        { <span className={
                            `badge fw-bold ${
                                data.attributes.payment_status == 1 ? 'badge-light-success' :'badge-light-danger' 
                                }`
                        }>
                        {                                data.attributes.payment_status == 1 ? 'Payé' :'non Payé' 
}
                    </span>}
                    </div>
                    <div className="col-4">
                        { <span className={
                            `badge fw-bold badge-light-primary`
                        }>
                        {                                data.attributes.payment_type2 
}
                    </span>}
                    </div>
                </div>
                <div className="row mt-2">
                <div className="text-gray-900 col-auto fw-bold ">Encaissée par:</div>
                    <div className="col">
                    <span className='text-uppercase'>{data.attributes.caissier_name}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
   

         <ItemDetailsSubVente data={data}/>

  
   
</div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            {loading? <button type="button" className="btn btn-primary" ><Loading/></button>
:            <button type="button" className="btn btn-primary" onClick={saveData}>Enregistrer</button>
}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
