import React, {ChangeEvent, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {createFormData, useCallApi} from '../../../modules/utils'
import {number, string} from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import {Loading} from '../../Loading'
import {check} from 'prettier'
import {useAuth} from '../../../modules/auth'

type Props = {
  data: any

  // deleteVille:(id:number)=>void
}
export const ModalUpdateUtilisateur: React.FC<Props> = ({data}) => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [image, setImage] = useState<File | any>([])

  const [filess, setFiles] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [chejed, setchejed] = useState(data.attributes.status === '1')
  const [roles, setRoles] = useState<any>([])

  const [utilisateurUpdate, setUtilisateurUpdate] = useState({
    role: data.attributes.role[0]?.id,
    first_name: data.attributes.first_name,
    last_name: data.attributes.last_name,
    phone: data.attributes.phone,
    email: data.attributes.email,
    product_category_id: data.attributes.product_category_id,
    product_price: data.attributes.product_price,
    notes: data.attributes.notes,
    status: data.attributes.status,
    price_purchase: data.attributes.price_purchase,
    nbrpoints: data.attributes.nbrpoints,
  })
  // const getCategories = async () => {
  //     const {data} = await callApi({
  //       route: 'api/product-categories',
  //       method: 'GET',
  //     })
  //     setCategories(data.data)
  //     // setLoading(false)
  //   }
  const updateProduct = async () => {
    setLoading(true)
    const dataTosend = {
      barcode_symbol: data.attributes.barcode_symbol,
      images: filess,
      role_id: utilisateurUpdate.role,
      first_name: utilisateurUpdate.first_name,
      phone: utilisateurUpdate.phone,
      email: utilisateurUpdate.email,
      last_name: utilisateurUpdate.last_name,
      product_category_id: utilisateurUpdate.product_category_id,
      product_price: utilisateurUpdate.product_price,
      notes: utilisateurUpdate.notes,
      status: utilisateurUpdate.status,
      price_purchase: utilisateurUpdate.price_purchase,
      nbrpoints: utilisateurUpdate.nbrpoints,
    }

    const formData = createFormData(dataTosend, image)
    try {
      const dat = await callApi({
        route: `api/users/` + data.id,

        method: 'PUT',

        body: dataTosend,
      })
      toast.success('modifié avec succes')

      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)

      setLoading(false)
    }

    // setLoading(false)
    // Toast.success('modifié')
  }
  const getRoles = async () => {
    try {
      const data = await callApi({
        route: 'api/roles',
        method: 'GET',
      })
      setRoles(data.data.data)
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  useEffect(() => {
    getRoles()
  }, [])
  return (
    <>
      <div
        className='modal fade'
        id={'ModalUpdateUtilisateur' + data.id}
        tabIndex={-1}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' style={{maxWidth: '50%'}}>
          <div className='modal-content'>
            <div className='modal-header ' >
              <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                Modfification de l'Employé:{' '}
                {data.attributes.first_name + ' ' + data.attributes.last_name}
              </h1>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <form>
                <div className='form-group mb-5  '></div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Nom de famille :
                      </label>
                      <input
                        type='text'
                        value={utilisateurUpdate.first_name}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setUtilisateurUpdate({...utilisateurUpdate, first_name: e.target.value})
                        }
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Prénom:
                      </label>
                      <input
                        name='last_name'
                        type='text'
                        value={utilisateurUpdate.last_name}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setUtilisateurUpdate({
                            ...utilisateurUpdate,
                            last_name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        Email :
                      </label>
                      <input
                        name='email'
                        type='email'
                        value={utilisateurUpdate.email}
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setUtilisateurUpdate({
                            ...utilisateurUpdate,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Tel :
                      </label>
                      <input
                        value={utilisateurUpdate.phone}
                        name='phone'
                        type='phone'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                        onChange={(e: any) =>
                          setUtilisateurUpdate({
                            ...utilisateurUpdate,
                            phone: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        Date de Début :
                      </label>
                      <input
                        name='date_d'
                        type='date'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>
                  <div className='col'>
                    {' '}
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Date de Fin :
                      </label>
                      <input
                        name='date_f'
                        type='date'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        {' '}
                        Salaire :
                      </label>
                      <input
                        name='salaire'
                        type='number'
                        className='form-control'
                        id='exampleInputEmail1'
                        aria-describedby='text'
                      />
                    </div>
                  </div>
                </div>
                <div className='row  '>
                  <div className='col'>
                    <div className='form-group mb-4 fw-bold'>
                      <label htmlFor='exampleInputEmail1'> Role :</label>
                      <div className=' p-2 form-control'>
                        {roles &&
                          roles.map((role: any, index: number) => (
                            <div className='form-check mb-2' key={index}>
                              <input
                                value={role.id}
                                className='form-check-input '
                                type='radio'
                                name='role'
                                id={`roled_id${role.id}`}
                                onClick={(e: any) =>
                                  setUtilisateurUpdate({
                                    ...utilisateurUpdate,
                                    role: e.target.value,
                                  })
                                }
                              />
                              <label className='form-check-label' htmlFor={`roled_id${role.id}`}>
                                {role.attributes.display_name}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {/* <div className='col'>
                    <div className='form-group mb-4 fw-bold'>
                      <label htmlFor='exampleInputEmail1'>Accèss Magasin</label>
                      <div className=' p-2 form-control'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='magasin'
                            id='flexRadioDefault2'
                            defaultChecked
                          />
                          <label className='form-check-label' htmlFor='flexRadioDefault2'>
                            SOTEMACO VIP
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group d-flex fw-bold'>
                      {' '}
                      <label htmlFor='exampleInputEmail1'>Statut :</label>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          role='switch'
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const {checked} = e.target
                            setchejed(checked)
                          

                            setUtilisateurUpdate({...utilisateurUpdate, status: checked ? 1 : 0})
                          }}
                          checked={chejed}
                          id='flexSwitchCheckDefault'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                Annuler
              </button>
              {loading ? (
                <button type='button' className='btn btn-primary'>
                  <Loading />
                </button>
              ) : (
                <button type='button' onClick={updateProduct} className='btn btn-primary'>
                  valider
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
