import { Pagination } from '@mui/material';
import React, { useState } from 'react'
import { ListePayment } from '../../components/payment/ListePayment';

type Props = {
  bn?:boolean
  // deleteVille:(id:number)=>void
}
const ListPayment: React.FC<Props> = ({bn}) => {
  const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    function handlePageChange(event:any, value:any) {
      
      setCurrentPage(value);
  
    }
   
    // console.log(JSON.stringify(getModifiedData(), null, 2));
    
    return (
      <>
              <ListePayment bn={bn} states={{currentPage,setCurrentPage,totalItems,setTotalItems,setTotalPages}}/>
              <Pagination count={totalPages} page={currentPage} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/>
      </>
    )
}

export default ListPayment