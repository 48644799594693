import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {KTSVG} from '../../../_metronic/helpers'
import {ToastContainer, toast} from 'react-toastify'
import SearchComponent from '../SearchComponent'
import { ItemPayment} from './itemPayment'

type Props = {
  states: any
  bn?:boolean
  // deleteVille:(id:number)=>void
}
export const ListePayment: React.FC<Props> = ({states,bn}) => {
  const [payments, setpayments] = useState<any[]>([])

  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkCategorieId, setCheckCategorieId] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filtePayment, setfiltePayment] = useState<any>([])
  const [statusFournisseur, setStatusFournisseur] = useState("")

  const callApi = useCallApi()
  const getProducts = async () => {
    try {
      setLoading(true)

      const {data} = await callApi({
        route: bn? 'api/payment_purchase_invoiceds?page[size]=10&page[number]=' + states.currentPage : 'api/all_payments_sales?page[size]=10&page[number]=' + states.currentPage,
        method: 'GET',
      })

      const initialpayments = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setpayments(initialpayments)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
      setLoading(false)

    }
  }
  const statusChange = (e: any) => {}
  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedProducts = filtePayment.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedProducts.map((product: any) => product.data.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedProducts
      .filter((product: any) => product.checked)
      .map((product: any) => product.data.id)
    setCheckCategorieId(checkedIds)
    setfiltePayment(updatedProducts)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedProducts = filtePayment.map((product: any) =>
        product.data.id === productId ? {...product, checked} : product
      )
      setfiltePayment(updatedProducts)
      const checkedIds = updatedProducts
        .filter((product: any) => product.checked)
        .map((product: any) => product.data.id)
      setCheckCategorieId(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
     if (window.confirm('Voulez-vous vraiment supprimer ce fournisseur !' )) {
      ids.map(async (id: number) => {
     
        const filteredposts = payments.filter((product: any) => product.data.id !== id)
        setfiltePayment(filteredposts)
        toast.success('fournisseur supprimé')
        const {data} = await callApi({
          route: bn?'api/payment_purchase_invoiceds/' + id:`api/sales/${id}/payment`,
          method: 'DELETE',
        })   
       })
      }

  }

  const getPayment = async () => {
    try {
      setLoading(true)
      const {data} = await callApi({
        route: `api/${bn?`payment_purchase_invoiceds`:`all_payments_sales`}?page[size]=10&page[number]=1&filter[search]=${searchTerm}`,
        method: 'GET',
      })

      const initialProducts = data.data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setpayments(initialProducts)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(data.meta.total / data.meta.per_page))
      setLoading(false)
    } catch (error) {
      setLoading(false)

      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getProducts()
  }, [states.currentPage,bn])
  useEffect(() => {
    setfiltePayment(payments)
  }, [payments,bn])


  useEffect(() => {
    getPayment()
  }, [searchTerm, statusFournisseur,bn])
  console.log(bn);
  
  return (
    <>
    <ToastContainer/>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Liste des paiments {bn?"achats":"ventes"}
              <SearchComponent
                placeholder={'Recherche '}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
      
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filtePayment.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-150px '> Libellé {bn?'achat':'vente'}</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px '>Date de paiement</th>
                    <th className='min-w-150px text-start'>Type de Paiement</th>
                    <th className='min-w-150px text-start'>Montant</th>
                    <th className='min-w-150px text-start'>Porteur</th>
                    <th className='min-w-150px text-start'>Proprietaire</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filtePayment.map((payemnt: any, index: any) => {
                    return (
                      <ItemPayment
                      bn={bn}
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={payemnt.data}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
