import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {ToastContainer, toast} from 'react-toastify'
import {useAuth} from '../../modules/auth'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, useNavigate, useParams} from 'react-router-dom'
import { ItemSaleInvoiced } from './ItemSaleInvoiced'
import { FilterCmd } from '../../components/journalVente/FilterCmd'


export const ListSaleInvoiced = () => {
  const {numCmd,clients,hasPermission} = useAuth()
  
  const [subventes, setsubventes] = useState<any[]>([])
  const [date, setDate] = useState<string>("")
  const [client, setClient] = useState<any>("")



  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filtersubventes, setFiltersubventes] = useState<any>("")
 
  const callApi = useCallApi()
  
  const getsubsubventes = async () => {
    try {

      setLoading(true)
      const currentDate = new Date()
      const formattedDate = currentDate.toLocaleDateString('en-CA')
      const {data} = await callApi({
        route: `api/sale_invoiceds?filter[customer_id]=${client}&filter[date]=${date}`,
          method: 'GET',
      })

      const initialsubventes = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))
      
      setsubventes(initialsubventes)

      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedsubventes = filtersubventes.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedsubventes.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedsubventes
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckedProductIds(checkedIds)
    setFiltersubventes(updatedsubventes)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedsubventes = filtersubventes.map((product: any) =>
        product.id === productId ? {...product, checked} : product
      )
      setFiltersubventes(updatedsubventes)
      const checkedIds = updatedsubventes
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
      setCheckedProductIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
    ids.map(async (id: number) => {
      if (window.confirm('Voulez-vous vraiment supprimer cette vente !' )) {
        const filteredposts = subventes.filter((product: any) => product.id !== id)
        setFiltersubventes(filteredposts)
        toast.success('vente supprimée')
        const {data} = await callApi({
          route: 'api/sale_invoiceds/' + id,
          method: 'DELETE',
        })
      }
    })
  }
 

  useEffect(() => {
    // if(!bySale){
    //   setNumCmd("")
    // }
    getsubsubventes()
  }, [date,client])

  useEffect(() => {
    setFiltersubventes(subventes)
    

  }, [subventes])

 
  
  return (
    <>
        <ToastContainer/>

      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste des Factures</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
          >
            <>
              {/* begin::Filter Button */}

              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selectionnées
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteProduct(checkedProductIds)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
    <div className='col me-3'>
            <label className=' fs-7 text-gray-500 fw-bold'>Date facture:</label>
            <input
              type='date'
              className='form-control  form-control-solid'
              
              onChange={(e) => setDate(e.target.value)}

              // onBlur={handleBlur}
              // value={values.cp_date_debut}
            />
          </div>
            <div className='col'>
            <label className=' fs-7 text-gray-500 fw-bold mb-1'>Client:</label>
      
            <select
              name=''
              id=''
              className='form-select   form-select-solid'
              onChange={(e) => setClient(e.target.value)}

            >
              <option value="">choisir..</option>
              {clients &&
                clients.map((client:any, index:number) => {
                  return (
                    <option
                      key={index}
                      value={client.id}

                    >
                      {client.attributes.name}
                    </option>
                  )
                })}
            </select>
          </div>
                 
              </>
            )}
          {
           
          }
            
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filtersubventes.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    
                    
                    {/* <th className="">Pays</th> */}
                    {/* <th className=' '>Vendeur/Caisse</th>
                    <th className=' text-center'>Type</th> */}
                    <th className=' text-start'>Date</th>
                    <th className=' text-start'>Client</th>
                    <th className=' text-start'>Total</th>
                    <th className=' text-start'>Statut</th>
          
                    <th className=' text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filtersubventes.map((product: any, index: any) => {
                    return (
                      <ItemSaleInvoiced
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-start"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
