import React, {useEffect, useState} from 'react'
import '../../css/style.css'
import {KTSVG} from '../../../_metronic/helpers'
import { ToastContainer } from 'react-bootstrap'
import { Loading } from '../Loading'
import { ItemProductSale } from './ItemProductSale'
import { useAuth } from '../../modules/auth'
  type Props = {
  total?: number
  someInfo: any
  saveData: () => void
  update:boolean
}
export const ListProductSale: React.FC<Props> = ({update,total, someInfo, saveData}) => {
 // const [listProducts, setListProducts] = useState<any>(listProduct);

 const addRow = () => {
  const newData = {
    discount_amount: 0,
    discount_type: '1',
    discount_value: 0,

    net_unit_cost: 0,


    tax_amount: 0,
    tax_type: '1',
    tax_value: 0,

    id: null,
    product_id: null,
    product_cost: 0,

    quantity: 0,
    product_price: 0,
  }
  someInfo.setDataSaleUpdated({
    ...someInfo?.dataSaleUpdated,
    sale_items: [...someInfo?.dataSaleUpdated?.sale_items, newData],
  })
  // setListProducts([...listProducts, newData]);
}

const deleteRow = (index: number) => {
  const updatedList = [...someInfo?.dataSaleUpdated?.sale_items]
  updatedList.splice(index, 1)
  someInfo?.setDataSaleUpdated({
    ...someInfo?.dataSaleUpdated,
    sale_items: updatedList,
  })
  // setListProducts(updatedList);
}
useEffect(() => {
  calculateGrandTotal()
  
}, [someInfo?.dataSaleUpdated?.sale_items,someInfo?.dataSaleUpdated?.discount])

const calculateGrandTotal = () => {
  const subtotalArray = someInfo?.dataSaleUpdated?.sale_items.map((item: any) =>
     parseFloat(item.sub_total?item.sub_total : item?.data?.attributes?.sub_total)
  
   

  )

  const grandTotal = subtotalArray?.reduce((acc: any, curr: any) => acc + curr, 0)
  someInfo.setDataSaleUpdated((prevData: any) => ({
    ...prevData,
    grand_total: grandTotal,
  }))
  
}





return (
  <>
  {/* <ToastContainer/> */}
 
      <div className='card'>
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive' style={{height: "30em"}}>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
{/* begin::Table head */}
<thead>
  <tr className='fw-bold text-muted'>
    <th className='min-w-50px'>Nom Produit</th>
  

    <th className='min-w-50px'>Quantité</th>
    <th className='min-w-50px'>Prix de vente</th>
    <th className='min-w-50px'>Montant</th>
    <th className='min-w-10px text-end'>Actions</th>
  </tr>
</thead>
{/* end::Table head */}
{/* begin::Table body */}
<tbody>
  {
     someInfo?.dataSaleUpdated?.sale_items?.map((listP: any, index: number) => {
      return (
        <ItemProductSale
          calculateGrandTotal={calculateGrandTotal}
          index={index}
          data={listP}
          update={update}
          deleteRow={() => deleteRow(index)}
          someInfo={someInfo}
        />
      )
    })
  }
</tbody>
{/* end::Table body */}
<tfoot >
  <tr>
    <th colSpan={3}></th>
    <th colSpan={2} className='d-inline-block col-12'>
      <span className='text-gray-700 fw-bold'>Total:</span>{' '}
      <span id='app-order-total' className='text-gray-700 fw-bold'>
        {total ?
        someInfo?.dataSaleUpdated?.discount !=0 ? (total-(total*(someInfo?.dataSaleUpdated?.discount/100))).toFixed(2)
        
        : total.toFixed(2) : 0} DH
      </span>
    </th>
  </tr>
</tfoot>
</table>

            {/* end::Table */}
          </div>
          <div className='d-flex  justify-content-between'>
            <button type='button' onClick={addRow} className='btn btn-sm btn-primary '>
              <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
              Ajouter Article
            </button>
            {
               someInfo[2] ||  someInfo[3]
                ?
               <button type='button' className='btn btn-sm btn-primary '>
               <Loading/>
             </button>:
              <button type='button' onClick={saveData} className='btn btn-sm btn-primary '>
              <KTSVG path='/media/icons/duotune/arrows/arr016.svg' className='svg-icon-2' />
              Valider
            </button>
            }
           
          </div>

          {/* end::Table container */}
        </div>
      </div>
  </>
 
)
}
