import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

import { useCallApi } from '../../modules/utils'
import { toast } from 'react-toastify'
import { Loading } from '../../components/Loading'
import { ModalDetailsSubVente } from '../../components/journalVente/ModalDetailsSubVente'

type Props = {
  data: any
  handleProductCheckboxChange: any
  deleteProduct?: (id: number) => void
}
export const ItemSaleInvoiced: React.FC<Props> = ({
  data,
  handleProductCheckboxChange,
  deleteProduct,
}) => {
  const [loading, setLoading] = useState(false)
  const callApi=useCallApi();

  const genrateUrPdf=async(id:number)=>{

    try{
      setLoading(true)
      const {data} = await callApi({
        route:`api/sale-invoiced-pdf-download/${id}`,
        method: 'GET',
      })
      
      if (data && data?.data?.sale_pdf_url) {
        setLoading(false)
        toast.success("facture generée")
        // Open a new window with the purchase_pdf_url
        window.open(data.data.sale_pdf_url, '_blank');
    } else {
      setLoading(false)
  
        console.error('Purchase PDF URL not found in the response data.');
    }

    } catch (error) {
      toast.error("une erreur s'est produite !")
      setLoading(false)
      console.error('Error fetching product data:', error)
    }
    
    
  }
  return (
    <>
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
       
        {/* <td>
          <div className='d-flex justify-content-start flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.caissier_name}</span>
          </div>
        </td> */}
        {/* <td>
          <div className='d-flex justify-content-center text-start flex-column'>
            <span className='text-dark fw-bold  d-block fs-6'>
              <div className='card-toolbar ml-3'>
                <span className={' badge badge-light-success'}>{data.attributes.type}</span>
              </div>
            </span>
          </div>
        </td> */}
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-start d-block fs-6'>
              <div className='card-toolbar ml-3'>{`${ String(new Date(data.attributes.created_at).getDate()).padStart(2, '0')}/${
  new Date(data.attributes.created_at).getMonth() + 1
}/${new Date(data.attributes.created_at).getFullYear()}  ${
  new Date(data.attributes.created_at).getHours()-1
}:${
  new Date(data.attributes.created_at).getMinutes()
}:${
  new Date(data.attributes.created_at).getSeconds()
}` }   </div>    </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-start d-block fs-6'>
              <div className='card-toolbar ml-3'>{data?.attributes?.customer_name}</div>

            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-start d-block fs-6'>
              <div className='card-toolbar ml-3'>{data?.attributes?.grand_total?.toFixed(2)}</div>

            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <span className='text-dark fw-bold text-start d-block fs-6'>
              <div className='card-toolbar ml-3'>
                <span
                  className={`badge fw-bold  me-3 ${
                    data.attributes.status === 'valide'
                      ? 'badge-light-success'
                      : data.attributes.status === 'annuler'
                      ? 'badge-light-danger'
                      : data.attributes.status === 'en cours'
                      ? 'badge-light-warning'
                      : ''
                  }`}
                >
                  {data.attributes.status}
                </span>
                {
                  data.attributes.is_return==1?<span
                  className={`badge fw-bold badge-light-primary `}
                >
                  retournée
                </span>:<></>
                }
              </div>
            </span>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
           <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                data-bs-toggle='modal'
                title='details'
                data-bs-target={'#modaldetailssubventes' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/general/gen019.svg'  className='svg-icon-3' />{' '}
              </button>
             <ModalDetailsSubVente data={data} /> 
            
            </div>
            <div className='d-flex justify-content-end flex-shrink-0'>
            <Link
                to={ '/sale_invoiced/' + data?.id }
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                // onClick={()=>deleteProduct(data.id)}
              >
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </Link>

              {/* <ModalDetailsSubVente data={data} /> */}
            
            </div>
            <div className='d-flex justify-content-end flex-shrink-0'>
              {/* <button
                type='button'
                data-bs-toggle='modal'
                title='retourner la vente'
                data-bs-target={'#modaldetailssubventesforreturn' + data.id}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr054.svg' className='svg-icon-3 svg-custom' />{' '}
              </button> */}
              {/* <ModalDetailsSubVenteForReturn data={data}  /> */}
              {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>   */}

              {/* <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      // onClick={()=>deleteProduct(data.id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a> */}
            </div>
            {
              data.attributes.grand_total_invoiced==0?<></>:
              <div className='d-flex justify-content-end flex-shrink-0'>
              {
  
  
                  loading?
                  
                  <Loading/>: 
                  
                  <button
                  type='button'
                  title="telecharger facture"
                  onClick={()=>genrateUrPdf(data.id)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' />{' '}
                </button>
                }
               </div>
            }
          
          </div>
           
        </td>
      </tr>
    </>
  )
}
