import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {log} from 'console'
import {useCallApi} from '../../modules/utils'
import { Loading } from '../Loading'

type Props = {
  data: any
  handleProductCheckboxChange: any
  deleteProduct?: (id: number) => void
  bn?:boolean
}

export const ItemPayment: React.FC<Props> = ({
  data,
  bn,
  handleProductCheckboxChange,
  deleteProduct,
}) => {
  const callApi = useCallApi()
  const [loading, setloading] = useState(false)
  const [updatedData, setUpdatedData] = useState<any>()

  const onUpdateCategorie = async (id: number) => {
    setloading(true)
    const dataToSend = {
     
      payment_date: updatedData.payment_date,
      payment_type: updatedData.payment_type,
      amount:updatedData.amount,
      proprietaire:updatedData.proprietaire,
      porteur:updatedData.porteur,
    }

    try {
      const dat = await callApi({
        route: bn?`api/payment_purchase_invoiceds/` + id:`api/sales/${id}/payment`,
        method: bn?'PUT':'POST',
        body: dataToSend,
      })
      toast.success('modifié avec succes')
      setloading(false)

    } catch (error: any) {
      setloading(false)

      toast.error(error.response.data.message)
    }
  }
  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target
    setUpdatedData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setUpdatedData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }))
  }
  useEffect(() => {
    setUpdatedData(data.attributes)
  }, [data])
console.log(data);


  return (
    <>
      {' '}
      <tr>
        <td>
          {' '}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            <input
              type='checkbox'
              className='form-check-input'
              checked={data.checked}
              onChange={handleProductCheckboxChange(data.id)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-start'>
            <div className='symbol symbol-45px '></div>
            <div className='d-flex justify-content-start flex-column'>
              <input className='d-none' type='text' value={data.id} onChange={handleInputChange} />
              <span className='text-dark fw-bold  d-block fs-6'>{data.attributes.purchase}</span>

            </div>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-start flex-column'>
            <input
              className='form-control'
              type='date'
              name='payment_date'
              value={updatedData?.payment_date}
              onChange={handleInputChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center text-center flex-column'>
          <select
              className='form-select'
              name='payment_type'
              value={updatedData?.payment_type}
              onChange={handleChangeSelect}
            >
               <option value='1' selected={updatedData?.payment_type == 1 ? true : false}>
                Espèces
              </option>
              <option value='2' selected={updatedData?.payment_type == 2 ? true : false}>
                Chèques
              </option>
              <option
                value='2'
                selected={updatedData?.payment_type == 2 || updatedData?.payment_type == null ? true : false}
              >
               Effets
              </option>
            </select>
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
            <input
              className='form-control'
              type='text'
              name='amount'
              value={updatedData?.amount}
              onChange={handleInputChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
          <input
              className='form-control'
              type='text'
              name='porteur'
              value={updatedData?.porteur}
              onChange={handleInputChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-center flex-column'>
          <input
              className='form-control'
              type='text'
              name='proprietaire'
              value={updatedData?.proprietaire}
              onChange={handleInputChange}
            />
          </div>
        </td>
        <td>
          <div className='d-flex justify-content-end flex-shrink-0'>
            <div className='d-flex justify-content-end flex-shrink-0'>
              <button
                type='button'
                onClick={() => onUpdateCategorie(data.id)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                {
                  loading?<Loading/>:                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />

                }
              </button>
             
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}
