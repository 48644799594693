import React, { useEffect, useState } from 'react'
import { ListPurchase } from '../../components/purchase/ListPurchase'
import { Pagination } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useAuth } from '../../modules/auth';

type Props={
  returned:boolean
  bn?:boolean
}
export const Purchase:React.FC<Props> = ({returned,bn}) => {
 const {setCurrentPage,currentPage,totalPages,setTotalItems,totalItems,setTotalPages}=useAuth()
  function handlePageChange(event:any, value:any) {
    setCurrentPage(value);
  }

  
  

  
  return (
    <>
            <ListPurchase states={{returned}} bn={bn}/>
            {/* <Pagination count={20} page={10} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
            <Pagination count={totalPages} page={currentPage} color="primary"  onChange={handlePageChange}  className="d-flex justify-content-center"/>
    </>
  )
}
