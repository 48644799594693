// ModalUpdateClient.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAuth } from '../../modules/auth';
import { useCallApi } from '../../modules/utils';
import { toast } from 'react-toastify';
import { Loading } from '../Loading';

type Props = {
  data: any;
};

const ModalUpdateClient: React.FC<Props> = ({ data }) => {
  const { categories } = useAuth();
  const callApi = useCallApi();

  const [loading, setLoading] = useState(false);
  const [clientUpdate, setClientUpdate] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    raison_social: '',
    cordonnee: '',
    activite: '',
    rc: '',
    ice: '',
    payment_type: '',
    deadline_payment: '',
    plafond_customer: '',
    // Add other client properties here
  });

  useEffect(() => {
    setClientUpdate({
      name: data?.attributes.name || '',
      email: data?.attributes.email || '',
      address: data?.attributes.address || '',
      phone: data?.attributes.phone || '',
      raison_social: data?.attributes.raison_social || '',
      cordonnee: data?.attributes.cordonnee || '',
      activite: data?.attributes.activite || '',
      rc: data?.attributes.rc || '',
      ice: data?.attributes.ice || '',
      payment_type: data?.attributes.payment_type || '',
      deadline_payment: data?.attributes.deadline_payment || '',
      plafond_customer: data?.attributes.plafond_customer || '',
      // Set other properties accordingly
    });
  }, [data]);

  const updateClient = async () => {
    setLoading(true);

    // Assuming you have an API route for updating a client
    try {
      const response = await callApi({
        route: `api/customers/${data.id}`,
        method: 'PUT',
        body: clientUpdate,
      });

      // Assuming that the API response contains the updated client data
      // Call the onUpdate callback to update the client in the parent component

      // Optionally, you can show a success message
      console.log('Client updated successfully:');
      toast.success('Client modifié avec succès');
    } catch (error) {
      // Handle error, show error message, etc.
      console.error('Error updating client:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className='modal fade'
      id={'ModalUpdateClient' + data.id}
      tabIndex={-1}
      role='dialog'
      aria-labelledby='exampleModalLongTitle'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-scrollable modal-lg' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='exampleModalLabel'>
              Modification de client: {data.attributes.name}
            </h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>
          <div className='modal-body'>
            <form>
              <div className='row mb-4'>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='name' className='fw-bold'>
                      Nom & prénom :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.name}
                      className='form-control'
                      id='name'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, name: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='email' className='fw-bold'>
                      Email :
                    </label>
                    <input
                      type='email'
                      value={clientUpdate.email}
                      className='form-control'
                      id='email'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, email: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='address' className='fw-bold'>
                      Adresse :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.address}
                      className='form-control'
                      id='address'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, address: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='phone' className='fw-bold'>
                      Téléphone :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.phone}
                      className='form-control'
                      id='phone'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, phone: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='raison_social' className='fw-bold'>
                      Raison sociale :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.raison_social}
                      className='form-control'
                      id='raison_social'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, raison_social: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='cordonnee' className='fw-bold'>
                      Coordonnée :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.cordonnee}
                      className='form-control'
                      id='cordonnee'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, cordonnee: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='activite' className='fw-bold'>
                      Activité :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.activite}
                      className='form-control'
                      id='activite'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, activite: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='rc' className='fw-bold'>
                      RC :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.rc}
                      className='form-control'
                      id='rc'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, rc: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='ice' className='fw-bold'>
                      ICE :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.ice}
                      className='form-control'
                      id='ice'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, ice: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='payment_type' className='fw-bold'>
                      Mode de paiement :
                    </label>
                    <select
                      className='form-select form-select-solid'
                      id='payment_type'
                      value={clientUpdate.payment_type}
                      onChange={(e) => setClientUpdate({ ...clientUpdate, payment_type: e.target.value })}
                    >
                      <option value="" >choisir..</option>
                      <option selected={clientUpdate?.payment_type=="1"} value={1}>Espèces</option>
                      <option selected={clientUpdate?.payment_type=="2"} value={2}>Chèques</option>
                      <option selected={clientUpdate?.payment_type=="3"} value={3}>Effets</option>
                   
                    </select>
                  </div>
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='deadline_payment' className='fw-bold'>
                      Délai de paiement :
                    </label>
                    <input
                      type='date'
                      value={clientUpdate.deadline_payment}
                      className='form-control'
                      id='deadline_payment'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, deadline_payment: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='plafond_customer' className='fw-bold'>
                      Plafond Client :
                    </label>
                    <input
                      type='text'
                      value={clientUpdate.plafond_customer}
                      className='form-control'
                      id='plafond_customer'
                      onChange={(e) => setClientUpdate({ ...clientUpdate, plafond_customer: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              {/* Add other fields similarly */}
            </form>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
              Annuler
            </button>
            {loading ? (
              <button type='button' className='btn btn-primary'>
                <Loading />
              </button>
            ) : (
              <button type='button' onClick={updateClient} className='btn btn-primary'>
                Valider
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUpdateClient;
