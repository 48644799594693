import {Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import {Loading} from '../Loading'
import {ToastContainer, toast} from 'react-toastify'

import {useNavigate} from 'react-router-dom'
import { Autocomplete, TextField } from '@mui/material'

export const FormAddPayment = () => {
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
   const [purchases, setPurchases] = useState<any>([])
   const [selectedPurchase, setSelectedPurchase] = useState<any>(null);

   const initialValues = {
    purchase_id:null,
    payment_date: "",
    payment_type: selectedPurchase?.attributes?.payment_type || null,
    amount: 0.0,
    proprietaire:"",
    porteur:"",
  }
   const getPurchases = async ()=> {
    try {
      
     
        const data = await callApi({
          route: `api/purchases`,
          method: 'GET',
        })
        
     setPurchases(data.data.data)
      console.log(data.data.data);
      

      // setDetailP(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
console.log(selectedPurchase);

  const saveData= async(values:any)=>{
   setLoading(true)
   values["purchase_id"]=selectedPurchase.id
   values["payment_type"]=selectedPurchase.attributes?.payment_type

    console.log(values);
    try {
       const data = await callApi({
      route: `api/payment_purchase_invoiceds`,
      method: 'POST',
      body:values
    }) 
    setLoading(false)
    toast.success("ajouté avec success")
    } catch (error:any) {
      setLoading(false)
      toast.error(error.response.data.message)

    }
   
   
  }
useEffect(() => {
getPurchases()
}, [])



  return (
    <>
      <ToastContainer />
      <Formik
            initialValues={initialValues}
            onSubmit={(values: any, {resetForm}) => {
              saveData(values)
                resetForm()
               
            }}
          >
            {() => (
              <Form className='container'>
             <div className='row mb-3'>
                <div className=' col  bg-white w-100 p-3 border rounded-2  '>
                <div className=' fw-bold '>
                        <label></label>
                      </div>
                   
                    <Autocomplete
                    className='solid '
  disablePortal
  id="combo-box-demo"
  options={purchases}
  getOptionLabel={(option) => option.id + " - " + option.attributes.supplier_name}
  value={selectedPurchase}
  sx={{ width: 300 ,backgroundColor:"#f5f8fa"}}
  onChange={(event, newValue) => {
    setSelectedPurchase(newValue);

  }}
  renderInput={(params) => <TextField {...params} label="Achats" />}
/>
                  </div>

                  <div className='col'>
                    <div className='  bg-white w-100 p-3 border rounded-2  '>
                      <div className=' fw-bold '>
                        <label>Date de paiement :</label>
                      </div>
                      <div className='col'>
                        <Field
                        aut
                          type='date'
                          name='payment_date'
                          className='form-control  form-control-solid'
                          id='exampleInputEmail1'
                          aria-describedby='text'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                <div className=' col  bg-white w-100 p-3 border rounded-2  '>
                <div className=' fw-bold '>
                        <label>Mode  de paiement :</label>
                      </div>
                    <Field as="select" name='payment_type' id=''     className='form-select   form-select-solid'>
                      <option value="" >choisir..</option>
                      <option selected={selectedPurchase?.attributes?.payment_type==1}value={1}>Espèces</option>
                      <option selected={selectedPurchase?.attributes?.payment_type==2}value={2}>Chèques</option>
                      <option selected={selectedPurchase?.attributes?.payment_type==3}value={3}>Effets</option>
                    </Field>
                  </div>
                  <div className='col'>
                    <div className='  bg-white w-100 p-3 border rounded-2  '>
                      <div className=' fw-bold '>
                        <label>Montant :</label>
                      </div>
                      <div className='col'>
                        <Field
                          type='text'
                          value={selectedPurchase?.attributes?.grand_total}
                          name='amount'
                          
                          className='form-control  form-control-solid'
                          id='exampleInputEmail1'
                          aria-describedby='text'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3 ">
                <div className=' col  bg-white w-100 p-3 border rounded-2  '>
                <div className=' fw-bold '>
                        <label>Porteur  :</label>
                      </div>
                      <Field
                          type='text'
                          name='porteur'
                          className='form-control  form-control-solid'
                          id='exampleInputEmail1'
                          aria-describedby='text'
                        />
                  </div>
                  <div className='col'>
                    <div className='  bg-white w-100 p-3 border rounded-2  '>
                      <div className=' fw-bold '>
                        <label>Propritaire :</label>
                      </div>
                      <div className='col'>
                        <Field
                          type='text'
                          name='proprietaire'
                          className='form-control  form-control-solid'
                          id='exampleInputEmail1'
                          aria-describedby='text'
                        />
                      </div>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className='d-flex justify-content-center align-items-center'>
                        
                       {
                              loading? 
                                <button
                            type='button'
                            className='btn  w-25 btn-sm btn-primary'
                            
                          >
                           
                            
                            <Loading/> 
                          </button>: 
                          <button
                            type='submit'
                            className='btn  w-25 btn-sm btn-primary'
                            
                          >
                           
                            
                            Ajouter
                          </button>
                          }
                        </div>
                </div>


              </Form>
            )}
       </Formik>
     
    </>
  )
}
