import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useState} from 'react'
import {createFormData, useCallApi} from '../../modules/utils'
import {useAuth} from '../../modules/auth'

import {Loading} from '../Loading'
import {toast} from 'react-toastify'
import { ModalAddProduct } from '../produits/ModalAddProduct'
import { Autocomplete, TextField } from '@mui/material'

type Props={
  add?:boolean
}
export const ModalAddCategorie:React.FC<Props> = ({add}) => {
  const {getCategories,categories} = useAuth()
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [selectedCategorie, setselectedCategorie] = useState<any>(null);

  const initialValues = {
    name: '',
    order: '',
    magasin: '',
    parent:'',
    color: '',
    status: true,
  }
  const [image, setImage] = useState<File | any>([])

  const [filess, setFiles] = useState<string>()
  const handelSumit = async (values: any) => {
    // setLoading(true)
    const dataTosend = {
      name: values.name,
      product_category_id:selectedCategorie.id,
      color: values.color,
      status: values.status == true ? 1 : 0,
    }

    const formData = createFormData(dataTosend, image)
    try {
      const dat = await callApi({
        route: `api/product-categories`,
        method: 'POST',
        body: formData,
      })
      getCategories()
      toast.success('ajoute avec succes')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }
  return (
    <>
     <div
        className='modal fade'
        id='ModalAddCategorie'
        role='dialog'
        tabIndex={-1}
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg modal-dialog-centered' role='document' style={{maxWidth: '40%'}}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, {resetForm}) => {
              handelSumit(values)
                resetForm()
              //   setFiles("")
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header ' >
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouvelle catégorie :
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Nom :
                            </label>
                            <Field
                              name='name'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        {/* <div className='col-2'>
                          <div className='form-group'>
                            <label htmlFor='exampleColorInput' className='fw-bold'>
                              Color
                            </label>
                            <Field
                              name='color'
                              type='color'
                              className='form-control form-control-color'
                              id='exampleColorInput'
                              title='Choose your color'
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className='row mb-4'>
                        <div className='form-group'>
                          
                          <Autocomplete
                    className='solid '
  disablePortal
  id="combo-box-demo"
  options={categories}
  getOptionLabel={(option:any) =>option.attributes.name}
  value={selectedCategorie}
  sx={{ backgroundColor:"white"}}
  onChange={(event, newValue) => {
    setselectedCategorie(newValue);
  }}
  renderInput={(params) => <TextField{...params} label="Parent"  sx={{color:"black"}}/>}
/>
                        </div>
                      </div>

                    

                      {/* <div className='form-group d-flex fw-bold mt-4 '>
                        {' '}
                        <label htmlFor='exampleInputEmail1 '>Statut </label>
                        <div className='form-check form-switch ms-4'>
                          <Field
                            className='form-check-input'
                            type={'checkbox'}
                            name={'status'}
                            role='switch'
                            //   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            //     const { checked } = e.target;
                            //     setchejed(checked)
                            //   }}
                            id='flexSwitchCheckDefault'
                          />
                        </div>
                      </div> */}
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary' >
                        <Loading />
                      </button>
                    ) : (
                      
                      <button type='submit' className='btn btn-primary'   data-bs-dismiss='modal' data-bs-toggle={add?'modal':""}
                      data-bs-target={add?'#modaladdproduct':''}>
                        valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
